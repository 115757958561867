import axios from 'axios';
import { Tag, Typography, Popconfirm, message, Spin, Modal, Button, Row, Col, Card } from 'antd';
import {
  CloseOutlined,
  CheckCircleOutlined,
  DragOutlined
} from '@ant-design/icons';

export default {
  getApprovedPapers: async function (setFuncMap) {
    try {
      const res = await this.getApprovedPapersAction()
      setFuncMap.setApprovedPapers(res.data)
      setFuncMap.setIsPageLoading(false)
    } catch (error) {
    }
  },
  getApprovedPapersAction: () => {
    return axios({
      method: 'GET',
      url: `/site/paper-approved`
    })
  },
  generateApprovedSubmissionPaperMenu: function () {
    return [
      {
        title: ' ',
        dataIndex: 'title',
        key: 'title',
        render: (_, render) => {
          return <>
            <div style={{fontWeight: "bold"}}>{render.resultName}</div>
            <div>{render.callForPaperType}</div>
            <div>
              <div style={{fontWeight: "bold"}}>{render.titleCn}</div>
              <div style={{fontWeight: "bold"}}>{render.titleEn}</div>
            </div>
            <div><Tag>{render.author1}</Tag></div>
          </>
        }
      }]
  }
};
