import axios from 'axios';
import { toolUtils } from '../../../../utils/toolUtils.js'
import pfn from './SubmissionPaperFns.js'
export default {
  createSubmissionPaper: async function (conferenceUid, values, setFuncMap) {
    try {
      setFuncMap.setIsModalLoading(true)
      console.log({ values })
      const res = await this.createSubmissionPaperAction(conferenceUid, values)
      pfn.getSubmissionPapers(conferenceUid, setFuncMap)
      setFuncMap.setIsModalLoading(false)
      setFuncMap.message.open({
        key: "create",
        type: 'success',
        content: 'Create success',
        duration: 2,
      });
      setFuncMap.setModalConfig({
        isOpen: false,
      })
    } catch (error) {
      setFuncMap.setIsModalLoading(false)
      console.log(error)
    }
  },
  createSubmissionPaperAction: (conferenceUid, payload) => {
    return axios.post(`/conference/${conferenceUid}/submission-paper`, payload, {
      headers: {
        "role": "topUser"
      }
    })
  },
  getSubmissionPaperById: async function (id, conferenceUid, setFuncMap) {
    try {
      setFuncMap.setIsModalLoading(true)
      const res = await this.getSubmissionPaperByIdAction(conferenceUid, id);
      let data = res.data
      this.initSubmissionPaper(data, setFuncMap)
      setFuncMap.setInitialFormValues(data)
      setFuncMap.setFieldsValue(data)
      setFuncMap.setIsModalLoading(false)
    }
    catch (e) {
      setFuncMap.setIsModalLoading(false)
      console.log(e)
    }

  },
  getSubmissionPaperByIdAction: (conferenceUid, id) => {
    return axios.get(`/conference/${conferenceUid}/submission-paper/${id}`, {
      headers: {
        "role": "topUser"
      }
    })
  },
  initSubmissionPaper: function (data, setFuncMap) {
    this.initSubmissionPaperContactAuthorValue(data)
    this.initOldAuthorDataToAuthor1(data)
    this.initAbstractHelpData(data, setFuncMap)
  },
  initSubmissionPaperContactAuthorValue: function (data) {
    let keys = ["authorIscontact1", "authorIscontact2", "authorIscontact3", "authorIscontact4", "authorIscontact5", "authorIscontact6"]
    keys.forEach(key => {
      if (data[key] > 0) {
        data[key] = "是"
      }
      else {
        data[key] = "否"
      }
    })
  },
  initOldAuthorDataToAuthor1: function (data) {
    if (!data["author1"] && data["author"]) {
      data["author1"] = data["author"].toString()
    }
  },
  initAbstractHelpData: (data, setFuncMap) => {
    console.log("11")
    let cnWordsCount = toolUtils.calculateWords(data.abstractCn)
    let enWordsCount = toolUtils.calculateWords(data.abstractEn)
    setFuncMap.setabstractHelpData({
      cn: cnWordsCount ? `(${cnWordsCount}/${data.CNAbstractCount})` : "",
      en: enWordsCount ? `(${enWordsCount}/${data.ENAbstractCount})` : "",
    })
  },
  // updateSubmissionPaper: async function (conferenceUid, payload, setFuncMap) {
  //   setFuncMap.setIsModalLoading(true)
  //   console.log({ payload })
  //   const res = await this.updateSubmissionPaperAction(conferenceUid, payload, payload.id)
  //   pfn.getSubmissionPapers(conferenceUid, setFuncMap)
  //   setFuncMap.setIsModalLoading(false)
  //   setFuncMap.message.open({
  //     key: "update",
  //     type: 'success',
  //     content: 'Update success',
  //     duration: 2,
  //   });
  //   setFuncMap.setModalConfig({
  //     isOpen: false,
  //   })
  // },
  // updateSubmissionPaperAction: (conferenceUid, payload, id) => {
  //   return axios.post(`/conference/${conferenceUid}/submission-paper/${id}`, payload, {
  //     headers: {
  //       "role": "topUser"
  //     }
  //   })
  // },
  generateSubmissionPaperAttribute: function (abstractHelpData) {
    return [{
      label: "徵稿群組",
      type: "text",
      name: "callForPaperTypeGroup",
      required: false,
      rules: []
    },
    {
      label: "徵稿類別",
      type: "text",
      name: "callForPaperType",
      required: true,
      rules: []
    },
    {
      label: "稿件類別",
      type: "text",
      name: "paperType",
      required: true,
      rules: []
    },
    {
      label: "中文標題",
      type: "text",
      name: "titleCn",
      required: false,
      rules: []
    },
    {
      label: "英文標題",
      type: "text",
      name: "titleEn",
      required: true,
      rules: []
    },
    {
      label: `中文摘要${abstractHelpData.cn}`,
      type: "textarea",
      name: "abstractCn",
      required: false,
      rules: []
    },
    {
      label: `英文摘要${abstractHelpData.en}`,
      type: "textarea",
      name: "abstractEn",
      required: true,
      rules: []
    },
    {
      label: "中文關鍵字",
      type: "text-tag",
      name: "keywordsCn",
      required: false,
      rules: []
    },
    {
      label: "英文關鍵字",
      type: "text-tag",
      name: "keywordsEn",
      required: true,
      rules: []
    },
    {
      label: "作者1",
      type: "text",
      name: "author1",
      required: true,
      rules: []
    },
    {
      label: "作者1 所屬單位",
      type: "text",
      name: "authorUnit1",
      required: true,
      rules: []
    },
    {
      label: "作者1 預設通訊作者",
      type: "text",
      name: "authorIscontact1",
      rules: []
    },
    {
      label: "作者1 Email",
      type: "text",
      name: "authorContactWay1",
      rules: []
    },
    {
      label: "作者2",
      type: "text",
      name: "author2",
      rules: []
    },
    {
      label: "作者2 所屬單位",
      type: "text",
      name: "authorUnit2",
      rules: []
    },
    {
      label: "作者2 預設通訊作者",
      type: "text",
      name: "authorIscontact2",
      rules: []
    },
    {
      label: "作者2 Email",
      type: "text",
      name: "authorContactWay2",
      rules: []
    },
    {
      label: "作者3",
      type: "text",
      name: "author3",
      rules: []
    },
    {
      label: "作者3 所屬單位",
      type: "text",
      name: "authorUnit3",
      rules: []
    },
    {
      label: "作者3 預設通訊作者",
      type: "text",
      name: "authorIscontact3",
      rules: []
    },
    {
      label: "作者3 Email",
      type: "text",
      name: "authorContactWay3",
      rules: []
    },
    {
      label: "作者4",
      type: "text",
      name: "author4",
      rules: []
    },
    {
      label: "作者4 所屬單位",
      type: "text",
      name: "authorUnit4",
      rules: []
    },
    {
      label: "作者4 預設通訊作者",
      type: "text",
      name: "authorIscontact4",
      rules: []
    },
    {
      label: "作者4 Email",
      type: "text",
      name: "authorContactWay4",
      rules: []
    },
    {
      label: "作者5",
      type: "text",
      name: "author5",
      rules: []
    },
    {
      label: "作者5 所屬單位",
      type: "text",
      name: "authorUnit5",
      rules: []
    },
    {
      label: "作者5 預設通訊作者",
      type: "text",
      name: "authorIscontact5",
      rules: []
    },
    {
      label: "作者5 Email",
      type: "text",
      name: "authorContactWay5",
      rules: []
    },
    {
      label: "作者6",
      type: "text",
      name: "author6",
      rules: []
    },
    {
      label: "作者6 所屬單位",
      type: "text",
      name: "authorUnit6",
      rules: []
    },
    {
      label: "作者6 預設通訊作者",
      type: "text",
      name: "authorIscontact6",
      rules: []
    },
    {
      label: "作者6 Email",
      type: "text",
      name: "authorContactWay6",
      rules: []
    },
    ]
  }
}