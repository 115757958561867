import axios from 'axios';
import { toolUtils } from '../../../../utils/toolUtils.js'
import { Upload, message } from 'antd';
import {
    CloseOutlined,
    CheckCircleOutlined,
    DragOutlined
} from '@ant-design/icons';

export default {
    getConference: async function (props, setFuncMap) {
        try {
            const res = await this.getConferenceByUidAction(props.conferenceUid)
            props.setContentHeader(res.data.name)
            setFuncMap.setIsPageLoading(false)
        } catch (error) {
        }
    },
    getConferenceByUidAction: (conferenceUid) => {
        return axios({
            method: 'GET',
            url: `/conference/${conferenceUid}`,
            headers: {
                "role": "topUser"
            }
        })
    },
    getConfigs: async function (props, form, setFuncMap) {
        try {
            setFuncMap.setIsContentLoading(true)
            const res = await this.getConfigsAction(props.conferenceUid)
            let configMap = this.generateConfigObj(res.data)
            console.log({ configMap })
            form.setFieldsValue(configMap)
            setFuncMap.setEditorData(configMap.Footer_value)
            setFuncMap.setFileList([{
                name: "uploaded file",//`${window.CONFS.cloudDomain}/${data.coverImage}`,
                status: 'done',
                url: `${window.CONFS.cloudDomain}/${configMap.BannerIamge_value}`,
                thumbUrl: `${window.CONFS.cloudDomain}/${configMap.BannerIamge_value}`,
            }])
            setFuncMap.setIsContentLoading(false)
        } catch (error) {
            console.log(error)
        }
    },
    getConfigsAction: (conferenceUid) => {
        return axios({
            method: 'GET',
            url: `/conference/${conferenceUid}/configs`,
            headers: {
                "role": "topUser"
            }
        })
    },
    generateConfigObj: function (values) {
        let configMap = {}
        values.forEach(item => {
            configMap[`${item.functionKey}_id`] = item.id
            switch (item.type) {
                case "datetime":
                    configMap[`${item.functionKey}_value`] = toolUtils.convertStringToDatetime(item.datetimeValue)
                    break;
                case "number":
                    configMap[`${item.functionKey}_value`] = item.numberValue
                    break;
                case "string":
                    configMap[`${item.functionKey}_value`] = item.stringValue
                    break;
            }
        })
        return configMap
    },
    generateConfigPaylod: function (values, conferenceUid) {
        let keytypeMap = {
            BannerIamge: "string",
            IsRegistrationICEIAvailable: "boolean",
            RegistrationDeadline: "datetime",
            SubmissionDeadline: "datetime",
            ReviewDeadline: "datetime",
            PublishReviewResultDeadline: "datetime",
            FinalSubmissionDeadline: "datetime",
            IsPublishReviewResult: "boolean",
            ENAbstractCount: "number",
            CNAbstractCount: "number",
            Footer: "string"
        }
        let payloadObj = this.generatePayloadObj(values)
        console.log({ payloadObj })
        let payload = []
        for (var sysKey in payloadObj) {
            let type = keytypeMap[sysKey]
            let itemPayload = {
                conference_uid: conferenceUid,
                functionKey: sysKey,
                type: type,
                id: payloadObj[sysKey].id
            }
            switch (type) {
                case "string":
                    itemPayload.stringValue = payloadObj[sysKey].value
                    break;
                case "datetime":
                    itemPayload.datetimeValue = toolUtils.convertFormToDatetime(payloadObj[sysKey].value)
                    break;
                case "boolean":
                    itemPayload.type = "number"
                    itemPayload.numberValue = (payloadObj[sysKey].value) ? 1 : 0
                    break;
                case "number":
                    itemPayload.numberValue = payloadObj[sysKey].value
                    break;
            }
            payload.push(itemPayload)
        }
        console.log({ payload })
        return payload
    },
    generatePayloadObj: function (values) {
        let obj = {}
        for (var key in values) {
            if (!values[key]) {
                continue
            }
            let params = key.split("_")
            if (obj[params[0]]) {
                obj[params[0]][params[1]] = values[key]
            }
            else {
                obj[params[0]] = {
                    [params[1]]: values[key]
                }
            }
        }
        return obj
    },
    updateConfig: async function (values, props, setFuncMap) {
        try {
            let payload = this.generateConfigPaylod(values, props.conferenceUid)
            setFuncMap.setIsContentLoading(true)
            const res = await this.updateConfigAction(payload, props.conferenceUid)
            setFuncMap.setIsContentLoading(false)
            setFuncMap.message.open({
                key: "update",
                type: 'success',
                content: 'Update success',
                duration: 2,
            });
        } catch (error) {
            console.log("update config error")
            setFuncMap.setIsContentLoading(false)
            setFuncMap.message.open({
                key: "update",
                type: 'error',
                content: 'Update failed',
                duration: 2,
            });
            // console.log(error)
        }
    },
    updateConfigAction: (payload, conferenceUid) => {
        return axios({
            method: 'POST',
            url: `/conference/${conferenceUid}/configs`,
            headers: {
                "role": "topUser"
            },
            data: payload
        })
    },
    getConfig: async function (props, setFuncMap) {
        try {
            setFuncMap.setIsContentLoading(true)
            const res = await this.getConfigByIdAction(props.conferenceUid)
            let result = {}
            res.data.forEach(item => {
                result[item.lang] = item
            })
            setFuncMap.setConfigMap(result)
            setFuncMap.setIsContentLoading(false)
        } catch (error) {
        }
    },
    getConfigByIdAction: (conferenceUid) => {
        return axios({
            method: 'GET',
            url: `/conference/${conferenceUid}/lecturer`,
            headers: {
                "role": "topUser"
            }
        })
    },
    generateUploadProps: function (form, linkList, setFuncMap) {
        return {
            beforeUpload: (file) => {
                const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
                if (!isJpgOrPng) {
                    message.error('You can only upload JPG/PNG file!');
                    return Upload.LIST_IGNORE;
                }
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isLt2M) {
                    message.error('Image must smaller than 2MB!');
                    return Upload.LIST_IGNORE;
                }
                return true;
            },
            customRequest: (options) => {
                this.handleUpload(options, form)
            },
            listType: "picture-card",
            defaultFileList: linkList,
            maxCount: 1
        };
    },
    handleUpload: ({ file, onSuccess, onError, onProgress }, form) => {
        const formData = new FormData();
        formData.append('file', file);
        axios.post('/upload-files/top-user', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                "role": "topUser"
            },
            onUploadProgress: (event) => {
                const percent = Math.round((event.loaded * 100) / event.total);
                onProgress({ percent });
            },
        })
            .then(response => {
                let data = response.data
                onSuccess({ url: `${window.CONFS.cloudDomain}/${data.fileName}`, ...response.data });
                form.setFieldsValue({
                    BannerIamge_value: data.fileName,
                })
                // message.success(`${file.name} file uploaded successfully`);
            })
            .catch(error => {
                onError(error);
                message.error(`${file.name} file upload failed.`);
            });
    },
   
};
