import React, { useEffect, useState } from 'react';
// import 'antd/dist/antd.css';
import { Table, Input, Select, Switch, Form, message, Spin, Modal, Button, Row, Col } from 'antd';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import efn from './UserFns'
import './User.scss';
import UserUpsertPage from './UserUpsertPage'

function User(props) {
  const navigate = useNavigate()
  const location = useLocation()
  const [form] = Form.useForm();
  const [users, setUsers] = useState([])
  const [isPageLoading, setIsPageLoading] = useState(false)
  const [isTableLoading, setIsTableLoading] = useState(false)
  const [isSearchFormLoading, setIsSearchFormLoading] = useState(false)
  const [searchFormCriteria, setSearchFormCriteria] = useState({ submittedPaperTypes: [], conferences: [] })
  const [searchText, setSearchText] = useState()
  const { Search } = Input;
  const [modalConfig, setModalConfig] = useState({
    isOpen: false,
    title: "",
    content: ""
  })
  const [isButtonSortLoading, setIsButtonSortLoading] = useState(false)
  const setFuncMap = {
    message, form, setUsers, setIsPageLoading, setModalConfig,
    setIsTableLoading, setIsButtonSortLoading, setIsSearchFormLoading,
    setSearchFormCriteria
  }
  const columns = efn.generateUserMenu(props, setFuncMap, { navigate, location })
  const { feature, action } = useParams();
  const colSpan = 12;
  const onFinish = values => {
    console.log(values)
    efn.getUsers(props.conferenceUid, setFuncMap)
  };

  useEffect(() => {
    efn.getSearchUserCriteria(props.conferenceUid, setFuncMap)
  }, []);

  return (
    <Spin spinning={isPageLoading}>
      <div className="User">

        <Spin spinning={isSearchFormLoading}>
          <Row>
            <Col span={24}>
              <Form
                form={form}
                layout="horizontal"
                onFinish={onFinish}
              >
                <Row justify={'center'} gutter={colSpan}>
                  {efn.generateSearchAttribute(searchFormCriteria, props.conferenceUid, setFuncMap).map((item, key) => {
                    return <Col span={colSpan} key={key}>
                      <Form.Item
                        label={item.label}
                        name={`${item.key}`}
                        initialValue={item.defaultValue}
                      >
                        {item.type === "select-multiple" && <Select
                          mode="multiple"
                          allowClear
                          options={item.options}
                        />}
                      </Form.Item>
                    </Col>
                  })}
                </Row>
                <Row justify={"center"} gutter={16}>
                  <Col>
                    <Button type="primary" htmlType="submit">
                      查詢
                    </Button>
                  </Col>
                </Row>

              </Form>
            </Col>
          </Row>
          <Row style={{ marginTop: "24px" }}>
            <Col>
              <Search placeholder="input search text" onSearch={(val) => {
                setSearchText(val)
              }} style={{ width: "500px" }} allowClear />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Table dataSource={users} columns={columns} />
            </Col>
          </Row>
        </Spin>
        <Modal wrapClassName="modal-theme" title={modalConfig.title} open={modalConfig.isOpen} footer={[]} destroyOnClose
          onCancel={() => {
            setModalConfig({
              isOpen: false,
            })
          }}>
          {modalConfig.content}
        </Modal>
      </div>
    </Spin>
  );
}


export default User;
