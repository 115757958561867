import React, { useEffect, useState } from 'react';
// import 'antd/dist/antd.css';
import { Table, Input, message, Spin, Modal, Button, Row, Col, Badge, Popconfirm } from 'antd';
import {
  DownloadOutlined,
} from '@ant-design/icons';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import efn from './SubmissionPaperFns'
import './SubmissionPaper.scss';

function SubmissionPaper(props) {
  const navigate = useNavigate()
  const location = useLocation()
  const [submissionPapers, setSubmissionPapers] = useState([])
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [isTableLoading, setIsTableLoading] = useState(false)
  const [lastGetDataTime, setLastGetDataTime] = useState(null)
  const [searchText, setSearchText] = useState()
  const [isRefreshButtonLoading, setIsRefreshButtonLoading] = useState(false)
  const [isDownloadAllButtonLoading, setIsDownloadAllButtonLoading] = useState(false)
  const [isSpecificDownloadlButtonLoading, setIsSpecificDownloadlButtonLoading] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [modalConfig, setModalConfig] = useState({
    isOpen: false,
    title: "",
    content: ""
  })
  const [isBatchUpdateResultButtonLoading, setIsBatchUpdateResultButtonLoading] = useState(false)
  const [reviewResultTypes, setReviewResultTypes] = useState([])
  const [changedResultPapers, setChangedResultPapers] = useState({})
  const setFuncMap = {
    message, setSubmissionPapers, setIsPageLoading, setModalConfig,
    setIsTableLoading, setIsBatchUpdateResultButtonLoading, setReviewResultTypes,
    setChangedResultPapers, setLastGetDataTime, setIsRefreshButtonLoading,
    setIsDownloadAllButtonLoading, setIsSpecificDownloadlButtonLoading,
  }
  const columns = efn.generateSubmissionPaperMenu(props, setFuncMap, {
    reviewResultTypes, changedResultPapers
  })
  const { Search } = Input;
  useEffect(() => {
    efn.getSubmissionPapers(props.conferenceUid, setFuncMap)
  }, []);

  const rowSelection = {
    selectedRowKeys,
    onChange: (newKeys) => {
      setSelectedRowKeys(newKeys);
    },
    columnWidth: 60,
  };

  const onRowClick = (record) => {
    const key = record.key;
    const newSelectedRowKeys = selectedRowKeys.includes(key)
      ? selectedRowKeys.filter((item) => item !== key)
      : [...selectedRowKeys, key];
      setSelectedRowKeys(newSelectedRowKeys);
  };
  return (
    <Spin spinning={isPageLoading}>
      <div className="SubmissionPaper">
        <Spin spinning={isTableLoading}>
          <Row align="center" style={{ height: "48px" }}>
            <Col flex={4}>
              <Search placeholder="input search text" onSearch={(val) => {
                setSearchText(val)
              }} style={{ width: "500px" }} allowClear />
            </Col>
            <Col flex={20}>
              <Row justify="end" align="center">
                <Col style={{ paddingRight: "8px" }}>
                  {efn.calculatePaperResultsDiff(submissionPapers, changedResultPapers).length > 0 &&
                    <Popconfirm
                      placement="left"
                      title={`確認要更新?`}
                      onConfirm={() => {
                        efn.batchUpdatePaperResults(submissionPapers, changedResultPapers, props.conferenceUid, setFuncMap)
                      }}
                      okText="Yes"
                      cancelText="No"
                    ><Button
                      onClick={() => {

                      }}
                      loading={isBatchUpdateResultButtonLoading}>更新審查結果
                        <Badge
                          className="site-badge-count-109"
                          count={efn.calculatePaperResultsDiff(submissionPapers, changedResultPapers).length}
                          style={{
                            backgroundColor: '#ff8b8b',
                            margin: "0 5px"
                          }}
                        />
                      </Button>
                    </Popconfirm>}
                </Col>
                {selectedRowKeys.length > 0 && <Col>
                  <Popconfirm
                    placement="left"
                    title={`確認要下載 ${selectedRowKeys.length} 筆資料?`}
                    onConfirm={() => {
                      efn.batchDownloadSpecificPaperResults(props.conferenceUid, selectedRowKeys, setFuncMap)
                    }}
                    okText="Yes"
                    cancelText="No"
                  ><Button
                    onClick={(event) => {
                      event.preventDefault()
                    }}
                    loading={isSpecificDownloadlButtonLoading}>
                      <Badge
                        className="site-badge-count-109"
                        count={selectedRowKeys.length}
                        style={{
                          backgroundColor: '#1677fe',
                          margin: "0 5px"
                        }}
                      />
                      <DownloadOutlined />
                    </Button>
                  </Popconfirm>
                </Col>}
                <Col style={{ paddingRight: "8px" }}>
                  <Popconfirm
                    placement="left"
                    title={`確認要下載全部?`}
                    onConfirm={() => {
                      efn.batchDownloadAllPaperResults(props.conferenceUid, setFuncMap)
                    }}
                    okText="Yes"
                    cancelText="No"
                  ><Button
                    onClick={() => {

                    }}
                    loading={isDownloadAllButtonLoading}>下載全部稿件
                      <DownloadOutlined />
                    </Button>
                  </Popconfirm>
                </Col>
                <Col>
                  <Button loading={isRefreshButtonLoading} onClick={() => {
                    efn.getSubmissionPapers(props.conferenceUid, setFuncMap)
                  }}>刷新資料: {lastGetDataTime}</Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Table virtual scroll={{ x: 1500 }}
                dataSource={efn.getFilterSubmissionPapaers(searchText, submissionPapers)}
                columns={columns}
                rowSelection={rowSelection}
                onRow={(record) => ({
                  onClick: () => onRowClick(record),
                })} />
            </Col>
          </Row>
        </Spin>
        <Modal wrapClassName="modal-theme"
          width={"80%"}
          title={modalConfig.title}
          open={modalConfig.isOpen}
          footer={[]}
          destroyOnClose
          onCancel={() => {
            setModalConfig({
              isOpen: false,
            })
          }}>
          {modalConfig.content}
        </Modal>
      </div>
    </Spin>
  );
}

export default SubmissionPaper;
