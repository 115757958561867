import axios from 'axios';
import { toolUtils } from '../../../../../utils/toolUtils.js';
import { Upload } from 'antd';
import pfn from './UserSubmissionPaperFns.js'
export default {
  generateFinalVersionItmes: function (efn, t, setFuncMap) {
    return [{
      label: t('home.user.paper.finalVersion.CNFileFinalversion'),
      type: "file",
      key: "cnFinalVersion",
      rules: []
    }, {
      label: ' ',
      type: "hidden",
      key: "cnFinalVersionOriginFilename",
      rules: []
    }, {
      label: ' ',
      type: "hidden",
      key: "cnFinalVersionFilename",
      rules: []
    }, {
      label: t('home.user.paper.finalVersion.ENFileFinalversion'),
      type: "file",
      key: "enFinalVersion",
      required: true,
      rules: [{ required: true, message: `${t('home.user.paper.finalVersion.ENFileFinalversion')} is required` }]
    }, {
      label: ' ',
      type: "hidden",
      key: "enFinalVersionOriginFilename",
      rules: []
    }, {
      label: ' ',
      type: "hidden",
      key: "enFinalVersionFilename",
      rules: []
    }, {
      label: t('home.user.paper.finalVersion.CopyrightConfirmation'),
      type: "checkbox",
      key: "copyrightConfirmation",
      rules: [{ required: true, message: `${t('home.user.paper.finalVersion.CopyrightConfirmation')} is required` }]
    }, {
      label: t('home.user.paper.finalVersion.PrivacyConfirmation'),
      type: "checkbox",
      key: "privacyConfirmation",
      rules: [{ required: true, message: `${t('home.user.paper.finalVersion.PrivacyConfirmation')} is required` }]
    }, {
      label: ' ',
      type: "hide",
      key: "id",
      rules: []
    }]
  },
  generateUploadProps: function (form, name, fileList, setFuncMap) {
    return {
      beforeUpload: (file) => {
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          setFuncMap.message.error('Image must smaller than 2MB!');
          return Upload.LIST_IGNORE;
        }
        return true;
      },
      customRequest: (options) => {
        this.handleUpload(options, form, name, setFuncMap)
      },
      onPreview: toolUtils.handleUploadPreview,
      onRemove: (file) => {
        this.handleRemove(file, name, form, setFuncMap)
      },
      defaultFileList: fileList,
      maxCount: 1
    };
  },
  handleUpload: ({ file, onSuccess, onError, onProgress }, form, name, setFuncMap) => {
    const formData = new FormData();
    formData.append('file', file);
    axios.post('/upload-files/user', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        "role": "user"
      },
      onUploadProgress: (event) => {
        const percent = Math.round((event.loaded * 100) / event.total);
        onProgress({ percent });
      },
    })
      .then(response => {
        let data = response.data
        switch (name) {
          case "cnFinalVersion":
            setFuncMap.setIsCnShowUpload(false)
            setFuncMap.setCnFileList([{
              uid: data.fileName,
              name: data.originFileName,
              status: 'done',
              url: `${window.CONFS.cloudDomain}/${data.fileName}`,
            }])
            break;
          case "enFinalVersion":
            setFuncMap.setIsEnShowUpload(false)
            setFuncMap.setEnFileList([{
              uid: data.fileName,
              name: data.originFileName,
              status: 'done',
              url: `${window.CONFS.cloudDomain}/${data.fileName}`,
            }])
            break;
        }

        onSuccess({ url: `${window.CONFS.cloudDomain}/${data.fileName}`, ...response.data });
        form.setFieldsValue({
          [`${name}OriginFilename`]: data.originFileName,
          [`${name}Filename`]: data.fileName,
        })
        // message.success(`${file.name} file uploaded successfully`);
      })
      .catch(error => {
        onError(error);
        setFuncMap.message.error(`${file.name} file upload failed.`);
      });
  },
  handleRemove: (file, name, form, setFuncMap) => {
    switch (name) {
      case "cnFinalVersion":
        setFuncMap.setIsCnShowUpload(false)
        setFuncMap.setCnFileList([])
        break;
      case "enFinalVersion":
        setFuncMap.setIsEnShowUpload(false)
        setFuncMap.setEnFileList([])
        break;
    }
    form.setFieldsValue({
      [name]: [],
      [`${name}OriginFilename`]: null,
      [`${name}Filename`]: null,
    })
  },
  upsertFinalVersionPaper: async function (values, setFuncMap) {
    try {
      setFuncMap.setIsModalLoading(true)
      delete values.cnFinalVersion
      delete values.enFinalVersion
      delete values.copyrightConfirmation
      delete values.privacyConfirmation
      console.log({ values })
      const res = await this.upsertFinalVersionPaperAction(values)
      pfn.getUserSubmissionPapers(setFuncMap)
      setFuncMap.setIsModalLoading(false)
      setFuncMap.message.open({
        key: "create",
        type: 'success',
        content: setFuncMap.t(`system.updateSuccess`),
        duration: 2,
      });
      setFuncMap.navigate("/submission-paper")
    } catch (error) {
      setFuncMap.message.open({
        type: 'error',
        content: setFuncMap.t(`error.${error.response.status}`),
        duration: 2,
      });
      setFuncMap.setIsModalLoading(false)
      console.log(error)
    }
  },
  upsertFinalVersionPaperAction: (payload) => {
    let url = (payload.id) ? `/submission-paper/final-versoin/${payload.id}` : `/submission-paper/final-versoin`
    return axios.post(url, payload, {
      headers: {
        "role": "user"
      }
    })
  },
  getUserSubmissionPaperFinalVersionById: async function (id, setFuncMap) {
    try {
      setFuncMap.setIsModalLoading(true)
      const res = await this.getUserSubmissionPaperFinalVersionByIdAction(id);
      let data = res.data

      if (data.id) {
        data.copyrightConfirmation = true
        data.privacyConfirmation = true
        setFuncMap.setIsCnShowUpload(false)
        setFuncMap.setIsEnShowUpload(false)
        if (data.cnFinalVersionFilename) {
          data.cnFinalVersion = data.cnFinalVersionFilename
          setFuncMap.setCnFileList([{
            uid: data.cnFinalVersionFilename,
            name: data.cnFinalVersionOriginFilename,
            status: 'done',
            url: `${window.CONFS.cloudDomain}/${data.cnFinalVersionFilename}`,
          }])
        }
        else {
          setFuncMap.setCnFileList([])
        }
        if (data.enFinalVersionFilename) {
          data.enFinalVersion = data.enFinalVersionFilename
          setFuncMap.setEnFileList([{
            uid: data.enFinalVersionFilename,
            name: data.enFinalVersionOriginFilename,
            status: 'done',
            url: `${window.CONFS.cloudDomain}/${data.enFinalVersionFilename}`,
          }])
        }
        else {
          setFuncMap.setEnFileList([])
        }
        setFuncMap.setFieldsValue(data)
      }
      else {
        setFuncMap.message.open({
          key: "update",
          type: 'error',
          content: setFuncMap.t("system.notFoundAnnouncement"),
          duration: 2,
        });
        setFuncMap.navigate("/submission-paper")
      }
      setFuncMap.setIsModalLoading(false)
    }
    catch (e) {
      setFuncMap.setIsModalLoading(false)
      console.log(e)
    }

  },
  getUserSubmissionPaperFinalVersionByIdAction: (id) => {

    return axios.get(`/submission-paper/final-versoin/${id}`, {
      headers: {
        "role": "user"
      }
    })
  },
}