import React, { useCallback, useState, useEffect } from 'react';
import { Tag, Typography, Popconfirm, message, Spin, Modal, Button, Row, Col, Card } from 'antd';
import efn from './UserSubmissionPaperFns'
import {
  CloseOutlined,
  CheckCircleOutlined,
  DragOutlined
} from '@ant-design/icons';

function UserSubmissionPaperController({ render, setFuncMap, fn }) {
  return (
    <>
      {[0, 2].indexOf(render.status) > -1 && <Row justify={"end"} gutter={4}>
        <Col>
          <Popconfirm
            placement="left"
            title={`${setFuncMap.t("home.user.paper.action.check.submit")} ${render.titleCn ? render.titleCn + "/" : ""}${render.titleEn}?`}
            onConfirm={() => { efn.submitUserSubmissionPaper(render.id, render.status, setFuncMap) }}
            okText="Yes"
            cancelText="No"
          ><Button size="small" type="primary">{setFuncMap.t("home.user.paper.row.button.SubmitPaper")}</Button>
          </Popconfirm>
        </Col>
        <Col>
          <Button size="small" style={{ marginRight: "5px" }}
            onClick={() => { fn.navigate(`/submission-paper/edit/${render.id}`) }}>{setFuncMap.t("home.user.paper.row.button.Edit")}</Button>
        </Col>
        <Col>
          <Popconfirm
            placement="left"
            title={`${setFuncMap.t("home.user.paper.action.check.delete")} ${render.titleCn ? render.titleCn + "/" : ""}${render.titleEn}?`}
            onConfirm={() => { efn.deleteUserSubmissionPaper(render.id, setFuncMap) }}
            okText="Yes"
            cancelText="No"
          ><Button size="small" type="primary" danger><CloseOutlined /></Button>
          </Popconfirm>
        </Col>
      </Row>}
      {render.status == 1 && <Row justify={"end"} gutter={4}>
        <Col>
          <Popconfirm
            placement="left"
            title={`${setFuncMap.t("home.user.paper.action.cancel.submit")} ${render.titleCn ? render.titleCn + "/" : ""}${render.titleEn}?`}
            onConfirm={() => { efn.submitUserSubmissionPaper(render.id, render.status, setFuncMap) }}
            okText="Yes"
            cancelText="No"
          ><Button size="small" type="primary" danger>{setFuncMap.t("home.user.paper.row.button.Back")}</Button>
          </Popconfirm>
        </Col>
        <Col><Button size="small" onClick={() => { fn.navigate(`/submission-paper/view/${render.id}`) }}>{setFuncMap.t("home.user.paper.row.button.view")}</Button></Col>
      </Row>}
      {[3, 5, 7, 8].indexOf(render.status) > -1 && <Row justify={"end"} gutter={4}>
        <Col><Button size="small" onClick={() => { fn.navigate(`/submission-paper/view/${render.id}`) }}>{setFuncMap.t("home.user.paper.row.button.view")}</Button></Col>
      </Row>}
      {[4, 6].indexOf(render.status) > -1 && <><Row justify={"end"} gutter={4}>
        <Col><Button size="small" onClick={() => { fn.navigate(`/submission-paper/view/${render.id}`) }}>{setFuncMap.t("home.user.paper.row.button.view")}</Button></Col>
      </Row>
        {render.showUploadPaper && <Row justify={"end"} gutter={4}>
          <Col>
            <Popconfirm
              placement="left"
              title={`${setFuncMap.t("home.user.paper.action.check.submit")} ${render.titleCn ? render.titleCn + "/" : ""}${render.titleEn}?`}
              onConfirm={() => { efn.submitUserSubmissionPaper(render.id, render.status, setFuncMap) }}
              okText="Yes"
              cancelText="No"
            >
              {render.enFinalVersionFilename && <Button size="small" type="primary">{setFuncMap.t("home.user.paper.row.button.SubmitPaper")}</Button>}
            </Popconfirm>
          </Col>
          <Col><Button size="small" onClick={() => { fn.navigate(`/submission-paper/view/${render.id}/final-version/${render.finalVersionPaperId || "new"}`) }}>{setFuncMap.t("home.user.paper.row.button.UploadFinalVersionPaper")}</Button></Col>
        </Row>}</>}
    </>
  );
}

export default UserSubmissionPaperController;
