import React, { useState, useEffect } from 'react';
import efn from './UserSubmissionPaperFinalVersionUpsertPageFns.js'
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Spin, Form, Switch, Upload, Select, Input, Button, Row, Col } from 'antd';
import { toolUtils } from '../../../../../utils/toolUtils.js'
import { DataContext } from '../../../../../Context/DataContext.js';
import {
  UploadOutlined,
} from '@ant-design/icons';
const { TextArea } = Input;
const { Option } = Select;

const UserSubmissionPaperFinalVersionUpsertPage = (props) => {
  const { t } = React.useContext(DataContext)
  const [form] = Form.useForm();
  const [isModalLoading, setIsModalLoading] = useState(false)
  const { subAction, id, subId } = useParams();
  const [cnFileList, setCnFileList] = useState([])
  const [isCnShowUpload, setIsCnShowUpload] = useState(isNaN(parseInt(subId)))
  const [enFileList, setEnFileList] = useState([])
  const [isEnShowUpload, setIsEnShowUpload] = useState(isNaN(parseInt(subId)))
  const navigate = useNavigate()
  const location = useLocation()
  const colSpan = 12
  const setFuncMap = {
    setIsModalLoading,
    setCnFileList, setIsCnShowUpload,
    setEnFileList, setIsEnShowUpload,
    setFieldsValue: form.setFieldsValue,
    navigate,
    t,
    ...props.setFuncMap
  }
  const onFinish = values => {
    console.log('Form values:', values);
    values.submissionPaperId = parseInt(id)
    efn.upsertFinalVersionPaper(values, setFuncMap)

  };
  useEffect(() => {
    setIsModalLoading(true)
    if (!isNaN(parseInt(subId))) {
      efn.getUserSubmissionPaperFinalVersionById(subId, setFuncMap)
    }
    setIsModalLoading(false)
  }, [subId, location.search])

  useEffect(() => {
    setIsCnShowUpload(true)
  }, [cnFileList])

  useEffect(() => {
    setIsEnShowUpload(true)
  }, [enFileList])


  return (
    <Spin spinning={isModalLoading}>
      <Form
        form={form}
        layout="horizontal"
        onFinish={onFinish}
      >
        <Row gutter={16}>
          <Col span={24}>
            {efn.generateFinalVersionItmes(efn, t, setFuncMap).map((item, key) => {
              return <Row key={key} justify={'center'} gutter={16}>
                {item.type === "checkbox" && <Col span={colSpan}>
                  <Form.Item
                    label={item.label}
                    name={`${item.key}`}
                    valuePropName="checked"
                    rules={item.rules}
                  >
                    <Switch />
                  </Form.Item>
                </Col>}
                {item.type === "file" && <Col span={colSpan}>
                  <Form.Item
                    label={item.label}
                    name={`${item.key}`}
                    rules={item.rules}
                    style={{ height: "50px" }}
                  >
                    {item.key == "cnFinalVersion" && isCnShowUpload && <Upload {...efn.generateUploadProps(form, item.key, cnFileList, setFuncMap)}>
                      <Button icon={<UploadOutlined />}></Button>
                    </Upload>}
                    {item.key == "enFinalVersion" && isEnShowUpload && <Upload {...efn.generateUploadProps(form, item.key, enFileList, setFuncMap)}>
                      <Button icon={<UploadOutlined />}></Button>
                    </Upload>}
                  </Form.Item>
                </Col>}
                {item.type === "hidden" && <Col span={colSpan}>
                  <Form.Item
                    label=' '
                    name={`${item.key}`}
                    hidden
                  >
                    <Input type="hidden" />
                  </Form.Item>
                </Col>}
              </Row>
            })}
          </Col>
        </Row>
        <Row justify={"center"} gutter={16}>
          {["final-version"].indexOf(subAction) > -1 && <><Col>
            <Form.Item>
              <Button type="default" onClick={() => {
                navigate("/submission-paper")
              }}>
                取消
              </Button>
            </Form.Item>
          </Col>
            <Col>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  儲存
                </Button>
              </Form.Item>
            </Col></>}
        </Row>
        {id && <Form.Item
          name="id"
          hidden
        >
          <Input type="hidden" />
        </Form.Item>}
      </Form>
    </Spin>
  );
};

export default UserSubmissionPaperFinalVersionUpsertPage;