import React, { useEffect, useState } from 'react';
// import 'antd/dist/antd.css';
import { Table, Radio, message, Spin, Modal, Button, Row, Col, Card } from 'antd';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import efn from './UserSubmissionPaperFns'
import './UserSubmissionPaper.scss';
import UserSubmissionPaperUpsertPage from './UserSubmissionPaperUpsertPage'
import UserSubmissionPaperFinalVersionUpsertPage from './UserSubmissionPaperFinalVersionUpsertPage'
import { DataContext } from '../../../../../Context/DataContext';


function UserSubmissionPaper(props) {
  const { t } = React.useContext(DataContext)
  const navigate = useNavigate()
  const location = useLocation()
  const [userSubmissionPapers, setUserSubmissionPapers] = useState([])
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [isTableLoading, setIsTableLoading] = useState(false)
  const [modalConfig, setModalConfig] = useState({
    isOpen: false,
    title: "",
    content: ""
  })
  const [isButtonSortLoading, setIsButtonSortLoading] = useState(false)
  const setFuncMap = {
    message, setUserSubmissionPapers, setIsPageLoading, setModalConfig,
    setIsTableLoading, setIsButtonSortLoading, t
  }
  const columns = efn.generateUserSubmissionPaperMenu(props, setFuncMap, { navigate, location })
  const { feature, action, subAction } = useParams();

  useEffect(() => {
    if (!location.search) {
      efn.getUserSubmissionPapers(setFuncMap)
      processAction(action, subAction, setFuncMap)
    }
  }, [location.pathname, location.search]);

  return (
    <Spin spinning={isPageLoading}>
      <div className="UserSubmissionPaper">
        <Spin spinning={isTableLoading}>
          <Row justify={"end"}>
            <Col>
              {userSubmissionPapers.length < 2 &&
                <Button onClick={() => {
                  navigate(`${location.pathname}/new`)
                }}>{t('home.user.paper.button.UploadPaper')}</Button>}
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Table dataSource={userSubmissionPapers} columns={columns} />
            </Col>
          </Row>
        </Spin>
        <Modal wrapClassName="modal-theme" width="80%" title={modalConfig.title} open={modalConfig.isOpen} footer={[]} destroyOnClose
          onCancel={() => { navigate("/submission-paper") }}>
          {modalConfig.content}
        </Modal>
      </div>
    </Spin>
  );
}

const processAction = function (action, subAction, setFuncMap) {
  switch (action) {
    case "new":
      setFuncMap.setModalConfig({
        isOpen: true,
        title: setFuncMap.t("home.user.paper.frame.title.new"),
        content: <UserSubmissionPaperUpsertPage setFuncMap={setFuncMap} />
      })
      break;
    case "edit":
    case "view":
      if (subAction) {
        setFuncMap.setModalConfig({
          isOpen: true,
          title: setFuncMap.t("home.user.paper.frame.title.UploadFinalVersionPaper"),
          content: <UserSubmissionPaperFinalVersionUpsertPage setFuncMap={setFuncMap} />
        })
      }
      else {
        setFuncMap.setModalConfig({
          isOpen: true,
          title: "",
          content: <UserSubmissionPaperUpsertPage setFuncMap={setFuncMap} />
        })
      }

      break;
    default:
      setFuncMap.setModalConfig({
        isOpen: false,
        title: "",
        content: ""
      })

  }
}

export default UserSubmissionPaper;
